export const environment = {
  production: false,
  envLabel: 'STAGING',
  encryptedKey: 'U2FsdGVkX19IBL7Ybtgs8zTDq4RLiFFUprpfcxIV9eI=',
  version: '1.1.1',
  clientEndpoint: 'https://pilot.dvtime.se/',
  baseUrl: 'https://app-dvtimeapi-be-neu-001-dv-staging.azurewebsites.net/',
  hostName: 'https://app-dvtimeapi-be-neu-001-dv-staging.azurewebsites.net',
  restAPI: '/api/v1/',
  appKey: 'dvtimenextgen',
  translateURL: 'https://api.datavaxt.se',
  appsAPI: 'https://api.datavaxt.se/api/v1/applikation/se',
  GTM_ID: 'GTM-NW9FKWK3',
};
